<template>
  <Catalog @new="newEntity" v-model="entity" :validate="validate" name="Serie" :rules="rules" :headers="headers" :inputs="inputs" />
</template>

<script>
import Catalog from "../../../components/templates/BasicCatalog.vue";
import { InvoiceSerie } from "../../../models/comercial/InvoiceSerie";
import Session from "../../../mixins/sessionMixin";
import { HeaderGrid, Rule, CatalogInput, ValidateForm, satCatalogo } from '../../../utilities/General';

export default {
  props: {
    voucher_type: null
  },
  data() {
    return {
      test: null,
      entity: new InvoiceSerie(),
      inputs: [
        new CatalogInput("Serie", "serie", "text"),
        new CatalogInput("Siguiente Folio", "next_id", "number"),
        new CatalogInput("Tipo", "voucher_type", "dropdown", { options: [], options_value: "c_TipoDeComprobante", options_label: "Descripcion"}),
      ],
      rules: [
        new Rule({ name: "serie" }),
        new Rule({ name: "voucher_type" }),
        new Rule({ name: "next_id" }),
      ],
      headers: [
        new HeaderGrid("Serie", "serie"),
        new HeaderGrid("Tipo de Comprobante", "voucher_description", { type:"upper", formula: "search", expression: "find", data: [], data_key: "c_TipoDeComprobante", data_search_key: "voucher_type", data_value: "Descripcion"}),
        new HeaderGrid("Siguiente", "next_id"),
      ],
      validate: new ValidateForm(),
      c_TipoDeComprobante: [],
    }
  },
  mixins: [Session],
  components: { Catalog },
  methods: {
    newEntity() {
      this.entity = new InvoiceSerie(this.session); 
      this.validate.clear();
    },
  },
  async created() {
    this.validate = new ValidateForm(this.rules);
    this.entity = new InvoiceSerie(this.session); 
    //* Llenamos todos los campos de opciones
    this.c_TipoDeComprobante = await satCatalogo(13);
    if (this.voucher_type) {
      this.c_TipoDeComprobante = this.c_TipoDeComprobante.filter(x => x.c_TipoDeComprobante == this.voucher_type);
      this.entity.voucher_type = this.voucher_type;
    }
    this.headers[1].data = this.c_TipoDeComprobante;
    //* Asignamos
    this.inputs.find(x => x.model == "voucher_type").options = this.c_TipoDeComprobante;
  }
}
</script>

<style>

</style>