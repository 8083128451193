import {
    Abstract
} from './Abstract';

export class InvoiceSerie extends Abstract {
    constructor(session) {
        super('invoice/InvoiceSerie', session);
        this.serie = null;
        this.voucher_type = null;
        this.next_id = 1;
    }
}
